<template>
  <v-card class="mx-auto my-12" max-width="374">
    <template v-slot:loader="{ isActive }">
      <v-progress-linear
        :active="isActive"
        color="deep-purple"
        height="4"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img
      cover
      height="450"
      src="../assets/Enrico Michael Severe.jpeg"
    ></v-img>

    <v-card-item>
      <v-card-title>Enrico Michael Severe</v-card-title>
    </v-card-item>

    <div style="margin-left: 15px; font-weight: bold;">
        Private Researcher
      </div>

    <v-card-text>
      <div class="font-weight-bold">Favorite Quotes:</div>

      <div>
        "Walk with the wise and become wise, for a companion of fools suffers
        harm.” ― Solomon
      </div>

      <v-divider></v-divider>

      <div>
        "The quality of your decisions is the quality of your life.” ― Ray Dalio
      </div>

       <v-divider></v-divider>

      <div>
        "Risk means more things can happen, then will happen.” ― Elroy Dimson
      </div>

      <v-divider></v-divider>

      <div>
        "There's steps to success” ― Giannis Antetokounmpo
      </div>

      <v-divider></v-divider>

      <div>
        "Starting a company is easy, building a successful business is hard.” ― Charles Newhall III
      </div>

      <v-divider></v-divider>

      <div>
        "Success consists of going from failure to failure without a loss of enthusiasm.” ― Winston Churchill
      </div>

      <v-divider></v-divider>

      <div>
        "No man can become rich without himself enriching others.” ― Andrew Carnegie
      </div>

      <v-divider></v-divider>

      <div>
        "Change means traveling in uncharted waters.” ― John C. Maxwell
      </div>

      <v-divider></v-divider>

      <div>
        "If people are doubting how far you can go, go so far that you can't hear them anymore.” ― Michele Ruiz
      </div>

      <v-divider></v-divider>

      <div>
        "If you want to succeed, you should strike out on new paths rather then travel the worn paths of accepted success.” ― John D. Rockefeller
      </div>

      <v-divider></v-divider>

      
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
      ecosystem: [
        {
          text: 'vuetify-loader',
          href: 'https://github.com/vuetifyjs/vuetify-loader',
        },
        {
          text: 'github',
          href: 'https://github.com/vuetifyjs/vuetify',
        },
        {
          text: 'awesome-vuetify',
          href: 'https://github.com/vuetifyjs/awesome-vuetify',
        },
      ],
      importantLinks: [
        {
          text: 'Documentation',
          href: 'https://vuetifyjs.com',
        },
        {
          text: 'Chat',
          href: 'https://community.vuetifyjs.com',
        },
        {
          text: 'Made with Vuetify',
          href: 'https://madewithvuejs.com/vuetify',
        },
        {
          text: 'Twitter',
          href: 'https://twitter.com/vuetifyjs',
        },
        {
          text: 'Articles',
          href: 'https://medium.com/vuetify',
        },
      ],
      whatsNext: [
        {
          text: 'Explore components',
          href: 'https://vuetifyjs.com/components/api-explorer',
        },
        {
          text: 'Select a layout',
          href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
        },
        {
          text: 'Frequently Asked Questions',
          href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
        },
      ],
    }),
  }
</script>
