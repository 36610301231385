<template>
  <v-app>
    <!-- Splash screen -->
    <div v-if="showSplash" class="splash-screen">
 <v-avatar class="avatar">
          <img
            class="logo"
            src="../src/assets/EMS.png"
          />
        </v-avatar>
      <h1>Welcome to Enrico Michael Severe's Website</h1>
      <button @click="hideSplash" class="enter-button">Enter</button>
    </div>

    <!-- Main content -->
    <div v-else>
      <v-app-bar app color="#2C3E50" dark>
        <v-avatar class="avatar">
          <img
            class="logo"
            src="../src/assets/EMS.png"
          />
        </v-avatar>

        <div class="nav-links">
          <div class="nav-link bounce" @click="navigate('/')">About</div>
          <div class="nav-link bounce" @click="navigate('https://officialcluesofsuccess.com/guests')">Official Clues of Success</div>
          <div class="nav-link bounce" @click="navigate('https://open.spotify.com/show/2vAdpuAPCMp5WowahRCkuu')">Podcast</div>
          <div class="nav-link bounce" @click="navigate('https://officialcluesofsuccess.com/wisdom')">Wisdom</div>
        </div>
      </v-app-bar>

      <v-main>
        <router-view />
      </v-main>
      <v-footer class="footer" color="#2C3E50" dark>
        <span>&copy; 2023 - Enrico Michael Severe</span>
      </v-footer>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showSplash: true
    };
  },
  methods: {
    hideSplash() {
      this.showSplash = false;
    },
    navigate(link) {
      if (link.startsWith('http')) {
        window.open(link, '_blank');
      } else {
        this.$router.push(link);
      }
    }
  }
};
</script>

<style>
/* Add custom CSS styles here */

/* Splash screen styles */
.splash-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #2C3E50; /* Updated background color */
  color: #fff;
}

.splash-screen h1 {
  font-size: 24px;
  margin-bottom: 20px;
  animation: jump 1s infinite alternate; /* Added animation for jumping effect */
}

.splash-screen button {
  padding: 10px 20px;
  background-color: #fff;
  color: #2C3E50; /* Updated button color */
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.splash-screen button:hover {
  background-color: #ffd600;
}

.enter-button:hover {
  transform: scale(1.1); /* Added hover effect for the Enter button */
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

/* Navigation bar styles */
.nav-links {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-grow: 1;
}

.nav-link {
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  padding: 12px 20px;
  transition: all 0.3s ease;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

/* Bouncing animation for navigation links */
.bounce {
  animation: bounce 0.5s infinite alternate; /* Added bouncing animation */
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

/* Avatar styles */
.avatar {
  background-color: transparent; /* Transparent background */
}

.avatar img {
  border-radius: 50%; /* Make the image circular */
  border: 3px solid #fff; /* Add border to the circular image */
}

/* Footer styles */
.footer {
  text-align: center;
  padding: 12px;
  font-size: 14px;
  background-color: #2C3E50; /* Updated background color */
}
</style>